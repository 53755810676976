.admin-table {

    max-width: 116.25rem;
    margin-left: auto;
    margin-right: auto;

    tbody td {
        word-break: break-all;
    }

    .button-row {
        button {
            margin-top: 1.375rem;
        }
    }

}