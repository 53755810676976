.users-section-header {

    display: flex;
    align-items: center;

    padding: 20px 0 40px 0;

    .user-icon {
        width: 48px;
        height: 48px;

        margin-right: 16px;
    }

    .email-search-input {
        width: 15rem;
        margin-left: 16px;
    }

    .state-search-select, .privates-checkbox {
        margin-left: 16px;
    }

    .companies-checkbox {
        margin-left: 30px;
    }

    .reset-button {
        margin-left: auto;
    }
}