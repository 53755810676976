@import './assets/styles/text-styles';
@import './assets/styles/colors';
@import './assets/styles/sizes';
@import './assets/styles/containers';

* {
  margin: 0;
  padding: 0;
  border: 0;
}

html {
  height: 100%;
}
body {
  height: 100%;
  box-sizing: border-box;
}

#root, .app {
  height: 100%;
}

h2 {
  @include Title-2L-Black;
}

h4 {
  @include Title-4C-Black;
}


.error-label {
  @include SmallL-Orange;
}