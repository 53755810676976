@import '../../../../assets/styles/text-styles';
@import '../../../../assets/styles/colors';

.section-paged-list-header {

    display: flex;
    align-items: center;

    padding: 30px 0 40px 0;

    .contracts-icon {
        width: 48px;
        height: 48px;
        margin-right: 1rem;
    }

    .app-breadcrumb {
        margin-left: 1rem;
    }

    .section-page-title {
        display: flex;
        align-items: center;
    }

    @media screen and (max-width: 1520px) {
        
        align-items: flex-start;
        
    }

}