@import '../../../../assets/styles/text-styles';
@import '../../../../assets/styles/colors';

.manage-provider-dialog {

    p {
        @include Standard-24;
        margin-bottom: 1.875rem;
    }

    form {
        margin: 0px auto;
        width: 35.625rem;

        .cc-input-box {
            margin-bottom: 1.25rem;
    
            span {
                margin-bottom: 0.5rem;
                @include StandardL-Black;
    
                &.error-label {
                    @include SmallL-Orange;
                    margin-bottom: 0px;
                }
            }
    
            &:last-child {
                margin-bottom: 0px;
            }

        }

        .checkbox-container {
            
            margin-bottom: 1.25rem;
            
            span {
                @include StandardL-Black;
            }
        }

    }

}