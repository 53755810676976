@import './colors';

@font-face {
  font-family: OpenSans;
  src: local(OpenSans),
    url(../fonts/OpenSans-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: OpenSans;
  src: local(OpenSans-SemiboldItalic),
    url(../fonts/OpenSans-SemiBoldItalic.ttf) format("truetype");
  font-weight: 600;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: OpenSans;
  src: local(OpenSans-Semibold),
    url(../fonts/OpenSans-SemiBold.ttf) format("truetype");
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: OpenSans;
  src: local(OpenSans-Bold),
    url(../fonts/OpenSans-Bold.ttf) format("truetype");
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
}

@mixin Title-1 {
  font-family: OpenSans;
  font-size: 48px;
  font-weight: 600;
  color: $grey-1;
}

@mixin Title-2L-White {
  font-family: OpenSans;
  font-size: 32px;
  font-weight: bold;
  color: $white;
}

@mixin Title-2C-White {
  font-family: OpenSans;
  font-size: 32px;
  font-weight: bold;
  text-align: center;
  color: $white;
}

@mixin Title-2L-Black {
  font-family: OpenSans;
  font-size: 32px;
  font-weight: bold;
  color: $grey-1;
}

@mixin Title-3 {
  font-family: OpenSans;
  font-size: 32px;
  font-weight: 600;
  text-align: center;
  color: $grey-1;
}

@mixin Title-4L-Primary {
  font-family: OpenSans;
  font-size: 24px;
  font-weight: 600;
  color: $primary;
}

@mixin Title-4L-White {
  font-family: OpenSans;
  font-size: 24px;
  font-weight: 600;
  color: $white;
}

@mixin Title-4C-Black {
  font-family: OpenSans;
  font-size: 24px;
  font-weight: 600;
  text-align: center;
  color: $grey-1;
}

@mixin Title-4L-Black {
  font-family: OpenSans;
  font-size: 24px;
  font-weight: 600;
  color: $grey-1;
}

@mixin Standard-24 {
  font-family: OpenSans;
  font-size: 24px;
  color: $grey-1;
}

@mixin Standard-B {
  font-family: OpenSans;
  font-size: 18px;
  font-weight: bold;
  color: $grey-1;
}

@mixin ButtonC-White {
  font-family: OpenSans;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  color: $white;
}

@mixin Standard-M {
  font-family: OpenSans;
  font-size: 18px;
  font-weight: 600;
  color: $grey-1;
}

@mixin ButtonC-Black {
  font-family: OpenSans;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  color: $grey-1;
}

@mixin Nome-utente {
  font-family: OpenSans;
  font-size: 18px;
  font-weight: 600;
  font-style: italic;
  text-align: right;
  color: $grey-2;
}

@mixin StandardL-Grey {
  font-family: OpenSans;
  font-size: 18px;
  color: $grey-2;
}

@mixin StandardL-Black {
  font-family: OpenSans;
  font-size: 18px;
  color: $grey-1;
}

@mixin SmallC-Black {
  font-family: OpenSans;
  font-size: 16px;
  text-align: center;
  color: $grey-1;
}

@mixin SmallL-Orange {
  font-family: OpenSans;
  font-size: 16px;
  color: $tertiary;
}

@mixin SmallC-Grey {
  font-family: OpenSans;
  font-size: 16px;
  text-align: center;
  color: $grey-2;
}

@mixin SmallL-Black {
  font-family: OpenSans;
  font-size: 16px;
  color: $grey-1;
}

@mixin FooterR-Grey {
  font-family: OpenSans;
  font-size: 14px;
  text-align: right;
  color: $grey-2;
}

@mixin FooterL-Grey {
  font-family: OpenSans;
  font-size: 14px;
  color: $grey-2;
}