@import '../../../assets/styles/text-styles';
@import '../../../assets/styles/colors';

.transaction-section-header {

    display: flex;
    align-items: center;

    padding: 20px 0 40px 0;

    .card-icon {
        width: 48px;
        height: 48px;
        margin-right: 1rem;
    }

    .items-count {
        @include Standard-24;
        margin-left: 1rem;
    }

    .cc-input-box {
        margin-left: 1rem;
    }

    .custom-select {
        width: 12.5rem;
    }

}