@import '../../../../../../assets/styles/text-styles';
@import '../../../../../../assets/styles/colors';

.fare-conditions-content {
    padding-top: 1.5rem;
    display: flex;
    flex-direction: column;

    .error-label {
        @include SmallL-Orange;
        margin-top: 0.25rem;
    }

    .sub-cpo {
        width: fit-content;
        margin-top: 1.875rem;

        .checkbox-container span {
            @include StandardL-Black;
        }

        .cc-input-box {
            margin-top: 1.5rem;
            width: 160px;
        }
    }

    .socket-data {
        margin-top: 1.5rem;
        display: flex;
        flex-direction: row;

        .socket-type {
            width: 160px;
        }

        .power-ranges-column {
            display: flex;
            flex-direction: column;

            margin-left: 1rem;
            
            .power-ranges-row {
                display: flex;
                flex-direction: row;

                & > .cc-input-box:first-child {
                    margin-right: 1rem;
                }

            }
        
            & > span.error-label {
                @include SmallL-Orange;
                margin-top: 0.25rem;
            }
        }
    
        .power-range-selector {

            .input-fields-row {
                display: flex;
                flex-direction: row;

                .cc-input-box {
                    width: 160px;
                }

                .cc-input-box:first-child {
                    margin-right: 1rem;

                    width: 75px;
                }

            }

        }

    }

    .validity-periods-data {
        
        margin-top: 1.5rem;
        
        .checkbox-container span {
            @include StandardL-Black;
        }

        .time-intervals {
            margin-top: 0.5rem;
            display: flex;
            flex-direction: row;

            &.labeled {
                margin-top: 1rem;
            }

            .cc-input-box:first-child {
                width: 200px;
            }

            .time-interval-selector {
                margin-left: 1rem;
                min-width: 256px;

                .input-fields-row {
                    display: flex;
                    flex-direction: row;

                    .cc-input-box {
                        width: 120px;

                        &:first-child {
                            margin-right: 1rem;  
                        }

                    }

                    img {
                        width: 24px;
                        height: 24px;
                        align-self: center;

                        margin-left: 0.5rem;

                        &:first-child {
                            margin-left: 1rem;
                        }
                    }
                }

                .error-label {
                    @include SmallL-Orange;
                    margin-top: 0.25rem;
                }
            }
        }

    }

    p.notes {
        @include SmallL-Black;
        margin-top: 1.5rem;
    }

}