.empty-list-box {

    span {
        display: block;
        padding-bottom: 32px;
        text-align: center;
    }

    img {
        width: 50px;
        height: 50px;
        display: block;
        margin: auto;
    }
}