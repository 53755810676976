@import '../../../assets/styles/colors';

.notification-dialog-container {
    .notification-dialog {
        width: 600px;
        max-width: 600px;

        height: 300px;
        max-height: 300px;

        padding: 35px 50px;

        box-sizing: border-box;

        justify-content: center;
        align-items: center;
        text-align: center;

        overflow: hidden;
        
        img {
            width: 64px;
            height: 64px;

            margin-bottom: 30px;
        }
    }
}