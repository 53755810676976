@import '../../../assets/styles/text-styles';
@import '../../../assets/styles/colors';

.manage-backoffice-user-dialog {

    p {
        @include Standard-24;
        margin-bottom: 1.875rem;
    }
    
    .form-field {
        margin: 0px auto;
        width: 35.625rem;
    }

    .cc-input-box {
        margin-bottom: 1.25rem;

        span {
            margin-bottom: 0.5rem;
            @include StandardL-Black;

            &.error-label {
                @include SmallL-Orange;
                margin-bottom: 0px;
            }
        }

        &:last-child {
            margin-bottom: 0px;
        }

        input:disabled {
            border: none;
            padding: 0px !important;
            color: $grey-1 !important;
        }
    }

}