@import '../../../../../../assets/styles/text-styles';
@import '../../../../../../assets/styles/colors';

.fare-fee-data {
    display: flex;
}

.fare-fee-data .fare-fee-accordion {

    display: flex;
    flex-direction: column;

    p {
        @include StandardL-Black;
    }

    .add-other-fare {
        display: flex;
        flex-direction: row;

        .cc-input-box {
            margin-left: 1rem;
            width: 273px;
        }
    }

    .other-fee-data, & > .add-other-fare {
        margin-top: 1.875rem;
    }
    
}