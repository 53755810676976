@import '../../../../../assets/styles/text-styles';
@import '../../../../../assets/styles/colors';

.fare-data-content {
    display: flex;
    flex-direction: column;

    & > div {
        display: flex;
    }

    .section-title {
        margin-bottom: 1.875rem;

        .inflation {
            margin-left: auto;
            display: flex;
            flex-direction: row;
            align-items: flex-start;

            @include Standard-24;
            font-weight: normal;

            .cc-input-box {
                margin-left: 1rem;

                width: 170px;
            }

            .column {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
            }

            .spacing {
                margin-bottom: 12px;
            }
        }
    }

    .main-data {

        margin-bottom: 1.5rem;
        width: 60%;
        
        .cc-input-box {
            margin-right: 1rem;
            flex: 1;

            &:last-child{
                margin-right: 0;
            }
        }

        .cc-input-box:nth-child(2) {
            flex: 3
        }

        .cc-input-box:first-child, .custom-select {
            width: 140px;
        }

    }

}