.user-detail-header {

    display: flex;
    
    padding-bottom: 30px;

    .month-filter {
        width: 12.5rem;
        margin: 0 1rem;
    }

}

.user-detail-loader {
    margin: auto;
}