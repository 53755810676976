@import '../../../assets/styles/colors';
@import '../../../assets/styles/text-styles';

.user-detail-dialog {

    .shadow-container{
    
        box-shadow: none !important;
        border: 2px $grey-3 solid;

        span {
            @include Standard-M;
        }

    }

}