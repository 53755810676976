@import '../../assets/styles/colors';
@import '../../assets/styles/text-styles';

.not-found {
    background-color: $grey-1;
    height: 100%;

    .message {
        margin: 11.125rem auto auto;
        text-align: center;

        img {
            width: 80px;
            height: 80px;
            margin-bottom: 1.875rem;
        }

        h1 {
            @include Title-2C-White;
            margin-bottom: 0.5rem;
        }

        span {
            @include SmallC-Grey;
        }

    }

    button {
        width: fit-content;
        margin: auto auto 3.75rem;
    }

}