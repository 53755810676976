.providers-table {

    .provider-td {

        &:hover {
            cursor: pointer;
        }

        td.actions {

            display: flex;
            align-items: center;
            padding-right: 0 !important;

            .remove-item-icon {
                width: 24px;
                height: 24px;
            }

            .arrow-icon {
                
                margin-left: auto;

                width: 24px;
                height: 24px;
            }

        }

    }

}