@import '../../../../../assets/styles/text-styles';
@import '../../../../../assets/styles/colors';

.manage-fee-dialog {

    .cc-input-box, .styled-text-area {
        span {
            margin-bottom: 8px;
            @include StandardL-Black;
        }
    }

    .input-style-row {       
        display: flex;
        flex-direction: row;
        
        .cc-input-box, .styled-text-area {
            margin-right: 16px;
            flex: 1;

            &:last-child{
                margin-right: 0;
            }
        }
    }

    .custom-select {
        min-width: inherit !important;
    }

    .section-title {
        @include Title-4L-Black;
    }

    div.bordered {
        padding-bottom: 1.875rem;
        border-bottom: solid 1px $grey-3;

        &.no-padding {
            padding-bottom: 0;
        }
    }

}