@import '../../../../assets/styles/text-styles';
@import '../../../../assets/styles/colors';

.providers-section-header {

    display: flex;
    flex: 1;
    align-items: center;

    margin-left: 1.875rem;

    .country-search-select, .contract-status-search-select {
        margin-right: 1rem;
    }

    .email-search-input {
        width: 17rem;
    }

    .custom-select {
        width: 17rem;
    }

    .providers-filters {
        display: flex;
        flex-wrap: wrap;
        margin-right: 1rem;
    }

    .date-time-period-container {
        display: flex;
        flex-direction: row;
        margin-left: auto;
        align-items: center;

        span {
            @include Standard-M;

            &:first-child {
                margin-right: 1rem;
            }
        }

        .custom-select {
            width: 12rem;
        }
        
    }

    @media screen and (max-width: 1520px) {
        
        align-items: flex-start;
        
        .providers-filters .cc-input-box:last-child {
            margin-top: 1rem;
        }
        
    }

    @media screen and (max-width: 1193px) {
        
        align-items: flex-start;
        
        .providers-filters .cc-input-box {
            margin-top: 1rem;

            &:first-child {
                margin-top: 0;
            }
        }
        
    }

}