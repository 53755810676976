@import '../../../assets/styles/text-styles';
@import '../../../assets/styles/colors';

.admin-section-header {

    display: flex;
    align-items: center;

    max-width: 116.25rem;

    padding: 20px 0 40px 0;
    margin-left: auto;
    margin-right: auto;

    .settings-icon {
        width: 48px;
        height: 48px;
        margin-right: 1rem;
    }

    .items-count {
        @include Standard-24;
        margin-left: 1rem;
        margin-right: 1rem;
    }

    .cc-input-box {
        margin-left: 1rem;
    }

    .email-search-input {
        width: 18.75rem;
    }

    .custom-select {
        width: 15rem;
    }

    .items-upper {
        display: none;
    }

    button {
        margin-left: auto;
    }

    .admin-section-header-filters {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        flex-grow: 1;
        align-items: center;


        & > div:last-child {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
        }
    }

    .admin-section-header-title {
        display: flex;
        flex-direction: row;
    }

    @media screen and (max-width: 1445px) {
        
        .admin-section-header-title {
            align-self: flex-start;
        }
        
        .admin-section-header-filters {
    
            & > div:last-child {
                margin-top: 1.375rem;
            }
        }

        button {
            align-self: flex-end;
        }
        
    }

    @media screen and (max-width: 1129px) {
        
        .admin-section-header-filters {
    
            & > .cc-input-box:nth-child(2) {
                margin-top: 1.375rem;
            }
        }
        
    }

    @media screen and (max-width: 875px) {
        
        flex-direction: column;

        .admin-section-header-title {
            margin-left: auto;
            margin-right: auto;
            align-items: center;
        }
        
        .admin-section-header-filters {
            margin-top: 1.375rem;
            flex-direction: column;
            flex-wrap: nowrap;
            width: 100%;
            
            .custom-select, .cc-input-box {
                width: 100%;
                margin-left: 0px;
            }

            & > div:last-child {
                width: 100%;
            }

            .email-search-input {
                width: auto;
            }

        }

        button {
            margin-top: 1.375rem;
            width: 100%;
        }

        .items-upper {
            display: inline;
        }

        .items-right {
            display: none;
        }
        
    }

}
