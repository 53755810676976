@import '../../../assets/styles/text-styles';
.checkbox-container {
    display: flex;
    align-items: center;
    .checkbox {
        width: 24px;
        height: 24px;
        cursor: pointer;

        &.disabled {
            cursor: auto;
            opacity: 0.5;
        }
    }
    span {
        @include StandardL-Black;
        margin-left: 8px;
    }
}