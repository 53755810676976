@import '../../../../assets/styles/text-styles';
@import '../../../../assets/styles/colors';

.section-paged-list-header {
    align-items: center !important;
}

.contracts-section-header {

    display: flex;
    flex: 1;
    align-items: center !important;
    justify-content: flex-end;

    .back-to-cpo {
        margin-right: 20px;
        @include StandardL-Black;
        color: $tertiary;
    }

}

.contracts-table {
    
    .contracts-td {

        &:hover {
            cursor: pointer;
        }

        td.actions {

            display: flex;
            align-items: center;
            padding-right: 0 !important;

            .remove-item-icon {
                width: 24px;
                height: 24px;
            }

            .arrow-icon {
                
                margin-left: auto;

                width: 24px;
                height: 24px;
            }

        }

    }

}