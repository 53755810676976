.list-error-box {
    
    cursor: pointer;

    span {
        display: block;
        padding-bottom: 32px;
        text-align: center;
        cursor: pointer;
    }

    img {
        width: 50px;
        height: 50px;
        display: block;
        margin: auto;
    }
}