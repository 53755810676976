@import '../../assets/styles/colors';
@import '../../assets/styles/text-styles';

.login-page {
    background-color: $grey-1;

    .login-box {

        width: 640px;

        margin: auto;

        background-color: $white;

        display: flex;
        flex-direction: column;
        align-items: center;

        padding-bottom: 40px;

        img.logo {

            height: 50px;
            background-color: $grey-1;
            width: 100%;
            padding: 12px 0;

            box-sizing: border-box;
            box-shadow: 0 2px 20px 0 rgba(117, 117, 117, 0.5);

        }

        h4 {
            margin: 40px 0;
        }

        .input-container {
            margin: 0 40px 20px 40px;
            align-self: stretch;

        }

        .login-button {
            width: 10.3125rem;
        }

        .login-section-label {
            @include StandardL-Black;
            text-align: left;
            align-self: stretch;
            margin: 0 40px 8px 40px;
        }

        .buttons-container {
            display: flex;
            justify-content: space-between;
            align-self: stretch;
            margin: 20px 40px 0 40px;
        }
    }
}