.sortable-column-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .sort-arrow-container {
        img {
            width: 30px;
            height: 30px;
            cursor: pointer;
        }
    }
}