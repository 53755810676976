@import '../../../assets/styles/colors';
@import '../../../assets/styles/text-styles';

.dialog-container {
    .dialog-paper {
        padding: 1.875rem;
        width: 80%;
        max-width: 1200px;
    }

    .dialog-title {
        padding: 0;

        h2 {
            @include Title-1;

            box-sizing: border-box;

            border-bottom: 3px solid $grey-3;
            padding-bottom: 1.875rem;
        }
    }

    .dialog-content {
        padding: 1.875rem 0;

        span {
            @include Standard-24;
        }

        overflow-x: hidden;
    }

    .dialog-actions {
        padding: 0;

        :not(:first-child) {
            margin-left: 22px;
        }

        :last-child {
            margin-left: auto;
        }
    }

}