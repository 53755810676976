@import '../../../../../assets/styles/text-styles';
@import '../../../../../assets/styles/colors';

.contract-details-summary {

    @include StandardL-Black;
    margin-bottom: 1.875rem;

    .contract-detail-item {

        .item-title {
            @include Standard-B;
        }

        .item-value {
            @include Standard-M;
            margin-left: 0.5rem;
        }

    }

    .summary-title {

        display: flex;
        flex-direction: row;
        align-items: center;

        border-bottom: solid 1px $grey-3;
        padding-bottom: 15px;

        & > span:first-child {
            @include Title-4L-Black;
            margin-right: auto;
        }

        .contract-detail-item {
            margin-right: 1.875rem;
        }

        .change-button {
            margin-left: 0.5rem;
        }

    }

    .summary-data {

        padding-top: 1.25rem;
        
        display: flex;
        flex-direction: column;

        .contract-detail-item {
            margin-bottom: 1rem;
        }

        & > div:last-child {

            display: flex;
            flex-direction: row;
            align-items: flex-end;
            justify-content: space-between;

            .contract-detail-item {
                margin-bottom: 0;
            }

            .block-button, .unlock-button {
                margin-left: 0.5rem;
                text-decoration: underline;

                &:hover {
                    cursor: pointer;
                }
            }

            .block-button {
                color: $tertiary;
            }

            .unlock-button {
                color: $secondary
            }

        }

    }


}