@import '../../../assets/styles/text-styles';
@import '../../../assets/styles/colors';

.app-breadcrumb {

    img {
        width: 10px;
        height: 16px;
    }

    .MuiBreadcrumbs-separator {
        margin-left: 12px;
        margin-right: 9px;
    }

    .link {
        @include Standard-24;
        text-decoration: none;
    }

    .current-page {
        @include Title-4L-Black;
        text-decoration: underline;
    }

}