@import '../../../assets/styles/colors';
@import '../../../assets/styles/text-styles';

.paged-list .page-selector-row {

    display: inline-flex;
    margin-left: auto;
    align-items: center;

    .items-per-page-box {
        width: 3rem;
        height: 2rem;

        margin-left: 0.625rem;
        margin-right: 1.25rem;

        .items-per-page-input {
            text-align: center;
        }
    }

    .page-number {

        width: 36px;
        height: 22px;

        cursor: pointer;

        font-family: Montserrat;
        font-size: 1.125rem;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 24px;
        letter-spacing: 0.0187rem;
        text-align: center;

        @include SmallC-Black;

        :first {
            margin-left: 1.25rem;
        }
        :last-child {
            margin-right: 1.25rem;
        }


        &.current {
            width: 18px;
            height: 22px;

            margin-right: 8px;
            margin-left: 8px;

            background-color: $primary;
        }

    }

    .change-page-arrow {

        height: 1rem;
        width: 1rem;

        cursor: pointer;

        margin-right: 1.25rem;

        &.disabled {
            opacity: 0.2;
            cursor: not-allowed;
        }

        &.next {
            margin-left: 0.625rem;
        }

        &:first {
            margin-left: 1.25rem;
        }
        &:last-child {
            margin-right: 0;
        }
    }
}

.testsvg {
    fill: pink;

    path {
        fill:pink;
    }
}