@import '../../../assets/styles/_colors';
@import '../../../assets/styles/text-styles';

$table-header-height: 3.75rem;
$table-header-border-width: 0.1875rem;
$filler-height: 20vh;

/*
.list-box {
    display: flex;

    .filters-list {
        display: flex;
        flex-direction: column;
        overflow: hidden;

        .bs-input-box {
            margin-top: 1.25rem;
            white-space: nowrap;
        }
    }
}
*/
.paged-list {

    .title-row {
        padding-bottom: 1.25rem;
    }

    .page-title {

        margin: 0;

        display: inline-block;

        width: 100%;
        padding-bottom: 20px;

        text-align: left;
    }

    .table-column {
        flex: 1;

        .title-row {

            display: flex;
            align-items: center;

            img.calendar-icon {

                width: 38px;
                height: 38px;

                flex: 0 0 38px;

                margin-right: 1.125rem;
            }

            .order-label {
                justify-self: flex-end;
                margin-left: auto;

                color: $grey-1;


                margin-right: 0.3125rem;

                font-family: Montserrat-Medium;
                font-size: 1.5rem;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: 0.025rem;
            }


        }

        .table-wrapper {

            position: relative;

            /* .overlay {
                display: none;
                position: absolute;
                align-items: center;
                justify-content: center;

                position: absolute;
                top: $table-header-height + $table-header-border-width+1rem;
                bottom: 0.5rem;
                left: 0.5rem;
                right: 0.5rem;
                background-color: white;

                &.loading,
                &.empty {
                    display: flex;
                }

                &.error {
                    display: flex;
                }

            } */
        }

        tbody .filler, tfoot .filler {
            height: $filler-height;
        }

        .shadow-table {
            padding: 0 30px;

            .table-title {
                border-bottom: $table-header-border-width solid $grey-3;
                padding: 28px 0 16px 0;
                @include Title-4L-Black;
            }

            table {
                border-spacing: 0;
                width: 100%;

                td {
                    text-align: left;
                    height: 3.75rem;
    
                    &.bold {
                        @include Standard-B;
                    }
    
                    &.warning {
                        @include Standard-B;
                        color: $tertiary
                    }
    
                    &.success {
                        @include Standard-B;
                        color: $secondary
                    }
    
                    &.semibold {
                        @include Standard-M;
                    }
                }
    
                thead {
                    tr {
    
                        td {
    
                            @include Standard-M;
    
                            border-bottom: $table-header-border-width solid $grey-3;
                            max-width: 200px;
                            height: 39px;
    
                            span {
                                @include Standard-M;
                            }
    
                            &:last-child {
                                padding-right: 0;
                            }
                        }
    
                        &:first-child td {
                            padding: 8px 30px 8px 0px;
                            height: $table-header-height;
                        }
    
                    }
    
                }
    
                tbody {
                    position: relative;
    
                    tr {
    
                        &:not(.filler-row) {
                            &:hover {
                                background-color: $grey-3;
                            }
                        }
                    }
    
                    td {
                        // TODO @extend .text-standard;
                        border-bottom: 1px solid $color-table-divider;
                        height: 5rem;
                        max-width: 200px;
                        padding: 8px 30px 8px 0px;
                        word-break: break-all;
    
                        &.capitalized {
                            text-transform: capitalize;
                        }
                    }
    
                    .overlay {
                        background-color: white;
    
                        &.loading,
                        &.empty {
                            height: $filler-height;
    
                            .loader > * {
                                margin: auto;
                            }
                        }
        
                        &.error {
                            height: $filler-height;
                        }
                    }
    
                }
    
                tfoot tr td>div {
                    display: flex;
                    align-items: center;
                    padding: 1rem 0 2.75rem 0;
                }
            }
        
        }

    }
}