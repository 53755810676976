
.page {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.page-content {
    padding: 20px 30px 50px 22px;
    overflow: auto;
}

.card {
  box-shadow: 0 2px 20px 0 rgba(117, 117, 117, 0.5);
  background-color: white;
}