@import '../../../assets/styles/colors';
@import '../../../assets/styles/text-styles';

footer {

    background-color: $grey-1;
    height: 2.5rem;

    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;

    padding: 0 1.375rem;

    display: flex;
    align-items: center;
    justify-content: space-between;

    &:first-child {
        @include FooterL-Grey
    }

    &:last-child {
        @include FooterR-Grey
    }
}