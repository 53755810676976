@import '../../../../../../assets/styles/text-styles';
@import '../../../../../../assets/styles/colors';

.fare-fee-options {
        
    width: 65%;
    margin-top: 1.875rem;
    margin-bottom: 1rem;

    display: flex;
    flex-direction: column;

    .cc-input-box {
        margin-bottom: 1.5rem;
    }

    .MuiInput-root {
        width: 273px;
    }

    .start-costs {
        display: flex;
        flex-direction: row;
        align-items: center;

        .checkbox-container {

            span {
                @include StandardL-Black;
            }

            margin-left: 1rem;
            margin-top: 0.5rem;
        }
    }

    .grace-period {
        display: flex;
        flex-direction: row;
        align-items: center;

        .cc-input-box {
            margin-left: 1rem;

            &:first-child {
                margin-left: 0;
            }
        }

    }

    .price-value {
        display: flex;
        flex-direction: row;

        .input-style-row {
            margin-left: 1rem;
        }

        .cc-input-box {
            margin-bottom: 0;
        }

    }

}