@import '../../../assets/styles/colors';
@import '../../../assets/styles/text-styles';


@mixin disabled {
    border: 3px solid $grey-2;
    color: $grey-2;
    background-color: $grey-3;

    &:hover {
        cursor: not-allowed;
    }
}

.primary-button {

    @include ButtonC-Black;
    cursor: pointer;

    height: 2.5rem;

    padding: 0 1.375rem;
    border-radius: 2px;
    border: 3px solid $primary;


    color: $grey-1;
    background-color: $primary;

    &:hover {
        background-color: $primary-light;
    }

    &:disabled {
        @include disabled();
    }

    &.tertiary {

        background-color: $tertiary;
        border: 3px solid $tertiary;
        color: $white;

        &:hover {
            background-color: $tertiary-light;
            color: $grey-1;
        }

        &:disabled {
            @include disabled();
        }
    }

    &.dark {

        background-color: $grey-1;
        border: 3px solid $grey-1;
        color: $white;

        &:hover {
            background-color: $grey-2;
            color: $grey-1;
        }

        &:disabled {
            @include disabled();
        }
    }
}