@import '../../../../assets/styles/text-styles';
@import '../../../../assets/styles/colors';

.section-paged-list-header {
    align-items: center !important;
}

.contract-details-section-header {

    display: flex;
    flex: 1;
    align-items: center !important;
    justify-content: flex-end;

    .back-to-cpo {
        margin-right: 20px;
        @include StandardL-Black;
        color: $tertiary;
    }

}

.contract-details-table {
    
    .contract-details-td {

        &:hover {
            cursor: pointer;
        }

        .evse-id, .power-range {
            word-break: break-word !important;
        }

        .operator-notes {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        td.actions {

            display: flex;
            align-items: center;
            padding-right: 0 !important;

            .remove-item-icon {
                width: 24px;
                height: 24px;
            }

            .arrow-icon {
                
                margin-left: auto;

                width: 24px;
                height: 24px;
            }

        }

    }

    .item-costs-summary {
        
        td {
            height: 36px !important;
        
            span {
                margin-left: 1.5rem;
            }
        }

        &:hover {
            background-color: inherit !important;
        }
        div {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
        }

        .item-costs-row {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            .summary-costs-label {
                height: 24px;
                margin-left: 0px;
                margin-right: 13px;
                @include Standard-B;
            }
        }
    }

}