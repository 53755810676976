@import '../../../../assets/styles/text-styles';
@import '../../../../assets/styles/colors';



.accordion-list {
    padding: 15px 0px;
    box-sizing: border-box;

    position: relative;
    margin: 0px 50px 0px 0px;

    @media screen and (max-width: 1520px) {
        align-items: flex-start;
    }

    .MuiCollapse-wrapper{
        border-top: 3px solid $color-table-divider
    }

    .MuiAccordionDetails-root{
        display: contents !important;
        position: relative;
    }

    .MuiAccordionSummary-content {
        margin: 0px !important;
    }

    .page-content {
        padding: 0px;
    }
    
    .shadow-container{
        box-shadow:none !important;
    }

    .shadow-table {
        padding: 0px !important;
    }

    .app-accordion {
        box-shadow: 0 2px 20px 0 rgba(117, 117, 117, 0.5) !important;
    }
}

.accordion-list > * {
    flex: 1;
}
