$grey-1: #333333;
$grey-2: #adadad;
$grey-3: #d6d6d6;
$white: #ffffff;
$tertiary: #ff8a14;
$tertiary-light: #ffc489;
$tertiary-lighter: #fff0e1;
$primary: #f3f245;
$primary-light: #f9f8a2;
$secondary: #1cbf06;


$color-table-divider: $grey-3;