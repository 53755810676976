@import '../../../assets/styles/colors';
@import '../../../assets/styles/text-styles';


.app-accordion {
    width: 100%;

    position: inherit !important; // to hide default upper border
    padding: 21px !important;
    margin-bottom: 20px;

    .app-accordion-title {
        @include Title-4L-Black;
    }

    img.app-accordion-arrow {
        width: 16px;
        height: 10px;
    }

    .MuiAccordionSummary-content {
        margin-top: 17px;
        margin-bottom: 17px;
    }

}