@import "../../../assets/styles/colors";
@import "../../../assets/styles/text-styles";

$input-height: 32px;

@mixin StyledInput {
  border-radius: 4px;
  border: solid 1px #b4b4b4;
  background-color: $white;

  &:disabled {
    opacity: 0.5;

    &:focus-visible {
      outline: #b4b4b4;
    }

  }
}

.cc-input-box {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  position: relative;

  &.padded input {
    padding-right: 44px;
  }

  span {
    cursor: auto;
  }

  input {
    height: $input-height;
    @include StyledInput;
    padding: 0 8px;

    &::placeholder {
      @include StandardL-Grey;
      margin: 2px 20px 0 0;
    }

    @include StandardL-Black;
  }

  .error-label {
    @include SmallL-Orange;
    margin-top: 0.25rem;
  }

  img.input-icon {
    width: $input-height;
    height: $input-height;

    position: absolute;
    right: 10px;

    cursor: unset;
    pointer-events: none;

    &.clickable {
      cursor: pointer;
      pointer-events: unset;
    }
  }

  .custom-select {
    padding: 0 0.5rem;
    text-align: left;
    min-width: 180px;

    @include StyledInput;

    &.disabled {
      opacity: 0.5;
    }

    img.select-arrow-icon {
      width: 24px;
      height: 14px;
      margin-right: 0.375rem;
    }

    .custom-select-select:focus {
      background-color: transparent;
    }

    &::before,
    :hover,
    &::after {
      border-bottom: 0 !important;
    }

    .custom-select-icon {
      display: none;
    }

    &::after {
      content: "";

      background-color: pink;
      background: url(../../../assets/images/icon-arrow-down.svg);
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain;

      width: 24px;
      height: 14px;
      position: absolute;
      left: unset;
      right: 14px;
      top: 0;
      bottom: 0;
      margin-top: auto;
      margin-bottom: auto;

      transform: none;
      transition-duration: 0s;
    }

    &.opened::after {
      transform: rotate(180deg);
      transition-duration: 0s;
    }

    .custom-select-select {
      @include StandardL-Black;
      padding-right: 40px;
    }
  }
}

.MuiMenuItem-root.custom-select-clear-item {
  font-size: 1rem;
  font-style: italic;
  letter-spacing: 0.025rem;
  color: pink;
}

.MuiSelect-selectMenu {
  min-height: auto !important;
}

.MuiList-root {
  max-height: 260px;
}

.MuiPaper-root::-webkit-scrollbar {
  display: none;
}

.underline {
  color: green;
}

.styled-text-area {

  display: flex;
  flex-direction: column;
  flex: 1;

  textarea {
    resize: none;
    padding: 5px;

    @include StyledInput;
    @include StandardL-Black;

    &:read-only {
      opacity: 0.5;

      &:focus-visible {
        outline: #b4b4b4;
      }

    }
  }

}

.styled-date-picker,
.styled-time-picker,
.styled-file-icon {
  cursor: pointer !important;
}