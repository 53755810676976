@import '../../../../../assets/styles/text-styles';
@import '../../../../../assets/styles/colors';

.manage-contract-dialog {

    form {
        overflow-x: clip;
    }

    .section-title {
        @include Standard-24;
    }

    .cc-input-box,
    .styled-text-area {
        span {
            margin-bottom: 8px;
            @include StandardL-Black;
        }
    }

    .input-style-row {
        display: flex;
        flex-direction: row;

        .cc-input-box {
            margin-right: 16px;
            flex: 1;

            &:last-child {
                margin-right: 0;
            }
        }
    }

    .manage-contract-dialog-title {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .base-fee {
            display: flex;
            flex-direction: row;
            align-items: center;

            @include Standard-24;
            font-weight: normal;

            .cc-input-box {
                margin-left: 1rem;

                width: 140px;
            }
        }
    }

    .contract-data-content {
        padding-bottom: 30px;

        .provider-data {

            margin-top: 30px;
            display: flex;
            flex-direction: row;
            align-items: center;

            div.cc-input-box {

                margin-right: 16px;

                &:first-child {
                    width: 33%;
                }

                &:nth-child(2) {
                    width: 140px;
                }

                &:last-child {
                    flex: 1;
                    margin-right: 0;
                }

            }
        }

        .ngp-provider-data {

            margin-top: 30px;
            display: flex;
            flex-direction: row;
            align-items: center;

            div.cc-input-box {

                margin-right: 16px;

                &:first-child {
                    width: 45%;
                }

                &:nth-child(2) {
                    width: 140px;
                }

            }

            .icon-container {
                display: flex;
                align-items: center;
                margin: 30px 6px 6px 6px;
            }

            .styled-file-icon {
                margin: 0 4px;
            }
        }


        .input-style-row {
            margin-top: 20px;
        }

        border-bottom: solid 1px $grey-3;
    }

    .optional-info-content {

        padding-top: 30px;
        display: flex;
        flex-direction: column;

        .input-style-row {
            margin: 30px 0 20px 0;
        }

    }


    .styled-file-icon {
        height: 24px;
        width: 24px;
    }
}