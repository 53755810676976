@import '../../../assets/styles/colors.scss';
@import '../../../assets/styles/text-styles';


.top-nav {

    display: flex;
    flex-direction: row;
    background-color: $grey-1;
    height: 100px;
    align-items: center;
    padding: 0 1.875rem;

    .logo {
        height: 100%;
        width: 200px;
        min-width: 100px;
        background: transparent url('../../../assets/images/chargecar-com.svg') no-repeat center center;
        background-size: contain;
        margin-right: 24px;

        h1 { 
            position: absolute;
            width: 0;
            height: 0;
            overflow: hidden;
            display: block;
            left: -1000px;
            top: -1000px;
        }
    }

    .selected-country-label {
        @include StandardL-Grey;
        margin-right: 9.375rem;
    }

    ul {
        list-style-type: none;
        float: right;
        display: flex;
        align-items: center;

        margin-left: 1.875rem;

        li {

            display: flex;
            align-items: center;

            float: left;
            font-size: 1.5rem;
            margin-right: 2.5rem;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: 0.4px;
            color: white;

            a {
                @include Title-4L-White;
                text-decoration: none;

                &.selected {
                    @include Title-4L-Primary;
                }

            }
        }

        .username-item {
            span {
                font-family: Montserrat-MediumItalic;
                font-family: Montserrat;
                font-size: 24px;
                font-weight: 500;
                font-stretch: normal;
                font-style: italic;
                line-height: normal;
                letter-spacing: 0.4px;
            }

            img {
                width: 44px;
                margin-left: 16px;
            }
        }

    }

    .username {
        @include Nome-utente;
        margin-left: auto;
        margin-right: 14px;
    }
}